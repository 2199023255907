import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataSource } from 'components/DataSource';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { FilterTags } from 'components/FilterTags';
import { ImageViewer } from 'components/ImageViewer';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useListPage from 'hooks/useListPage';
const GommeListPage = (props) => {
		const app = useApp();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		},
		gom_classe_energetica: {
			tagTitle: "Classe En.",
			value: '',
			valueType: 'single',
			options: app.menus.gomClasseEnergetica,
		},
		gom_larghezza: {
			tagTitle: "Largh.",
			value: '',
			valueType: 'single',
			options: [],
		},
		gom_marca: {
			tagTitle: "Marca",
			value: [],
			valueType: 'multiple',
			options: [],
		},
		gom_altezza: {
			tagTitle: "A",
			value: '',
			valueType: 'single',
			options: [],
		},
		gom_stagione: {
			tagTitle: "Gom Stagione",
			value: [],
			valueType: 'multiple',
			options: app.menus.gomStagione,
		},
		gom_diametro: {
			tagTitle: "D",
			value: '',
			valueType: 'single',
			options: [],
		},
		gom_settore: {
			tagTitle: "Settore",
			value: '',
			valueType: 'single',
			options: [],
		},
		gom_aderenza_bagnato: {
			tagTitle: "Aderenza",
			value: '',
			valueType: 'single',
			options: app.menus.gomAderenzaBagnato,
		},
		gom_runflat: {
			tagTitle: "Runflat",
			value: '',
			valueType: 'single',
			options: app.menus.gomRunflat,
		},
		gom_rumorosita: {
			tagTitle: "Rumorosita",
			value: '',
			valueType: 'single',
			options: [],
		},
		gom_indice_carico: {
			tagTitle: "Carico",
			value: '',
			valueType: 'single',
			options: [],
		},
		gom_qualita: {
			tagTitle: "Qualita",
			value: '',
			valueType: 'single',
			options: app.menus.gomQualita,
		},
		gom_indice_velocita: {
			tagTitle: "Velocità",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue, setFilterOptions } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	function ActionButton(data){
		const items = [
		{
			label: "View",
			command: (event) => { app.navigate(`/gomme/view/${data.gom_ean}`) },
			icon: "pi pi-eye"
		},
		{
			label: "Edit",
			command: (event) => { app.navigate(`/gomme/edit/${data.gom_ean}`) },
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.gom_ean) },
			icon: "pi pi-trash"
		}
	]
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function GomFotoTemplate(data){
		if(data){
			return (
				<><ImageViewer imageSize="small" previewSize="" src={data.gom_foto} width="50px" height="50px" numDisplay={1} style={{maxWidth:'100%'}} /></>
			);
		}
	}
	function GomEanTemplate(data){
		if(data){
			return (
				<Link to={`/gomme/view/${data.gom_ean}`}> { data.gom_ean }</Link>
			);
		}
	}
	function PreIdDisponibilitaTemplate(data){
		if(data){
			return (
				<>{ data.prezzi_pre_id_disponibilita }</>
			);
		}
	}
	function PrePrezzoTemplate(data){
		if(data){
			return (
				<>{ data.prezzi_pre_prezzo }</>
			);
		}
	}
	function PreQtaTemplate(data){
		if(data){
			return (
				<>{ data.prezzi_pre_qta }</>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Caricamento in corso...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Nessun record trovato
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Elimina selezionato"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Record <b>{ recordsPosition } di { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="GommeListPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mt-1" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col-2 " >
                    <span className="p-input-icon-left w-full">
                    <i className="pi pi-search" />
                    <InputText placeholder="EAN" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                    </span>
                    <div className="card  smt-6 p-2">
                        <Title title="Classe En."  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                        <div className="">
                            <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_classe_energetica', e.value)} value={filters.gom_classe_energetica.value} optionLabel="label" optionValue="value" options={filters.gom_classe_energetica.options} placeholder="Classe En." >
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="col-2 comp-grid" >
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_larghezza', options)}  apiPath="components_data/gom_larghezza_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Largh."  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_larghezza', e.value)} value={filters.gom_larghezza.value} optionLabel="label" optionValue="value" options={filters.gom_larghezza.options} placeholder="L" >
                                </Dropdown>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_marca', options)}  apiPath="components_data/gom_marca_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Marca"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <MultiSelect className="w-full" optionLabel="label" optionValue="value" value={filters.gom_marca.value} onChange={(e) => setFilterValue('gom_marca', e.value)} options={filters.gom_marca.options} label="" >
                                </MultiSelect>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                </div>
                <div className="col-2 comp-grid" >
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_altezza', options)}  apiPath="components_data/gom_altezza_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Altezza"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_altezza', e.value)} value={filters.gom_altezza.value} optionLabel="label" optionValue="value" options={filters.gom_altezza.options} placeholder="A" >
                                </Dropdown>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                    <div className="card  smt-1 p-2">
                        <Title title="Stagione"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                        <div className="">
                            <MultiSelect className="w-full" optionLabel="label" optionValue="value" value={filters.gom_stagione.value} onChange={(e) => setFilterValue('gom_stagione', e.value)} options={filters.gom_stagione.options} label="" >
                            </MultiSelect>
                        </div>
                    </div>
                </div>
                <div className="col-2 comp-grid" >
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_diametro', options)}  apiPath="components_data/gom_diametro_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Diametro"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_diametro', e.value)} value={filters.gom_diametro.value} optionLabel="label" optionValue="value" options={filters.gom_diametro.options} placeholder="D" >
                                </Dropdown>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_settore', options)}  apiPath="components_data/gom_settore_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Settore"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_settore', e.value)} value={filters.gom_settore.value} optionLabel="label" optionValue="value" options={filters.gom_settore.options} placeholder="Settore" >
                                </Dropdown>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                </div>
                <div className="col-1 comp-grid" >
                    <div className="card  smt-1 p-2">
                        <Title title="Aderenza"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                        <div className="">
                            <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_aderenza_bagnato', e.value)} value={filters.gom_aderenza_bagnato.value} optionLabel="label" optionValue="value" options={filters.gom_aderenza_bagnato.options} placeholder="AD" >
                            </Dropdown>
                        </div>
                    </div>
                    <div className="card  smt-1 p-2">
                        <Title title="Runflat"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                        <div className="">
                            <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_runflat', e.value)} value={filters.gom_runflat.value} optionLabel="label" optionValue="value" options={filters.gom_runflat.options} placeholder="Run" >
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="col-1 comp-grid" >
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_rumorosita', options)}  apiPath="components_data/gom_rumorosita_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Rumore"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_rumorosita', e.value)} value={filters.gom_rumorosita.value} optionLabel="label" optionValue="value" options={filters.gom_rumorosita.options} placeholder="Rum" >
                                </Dropdown>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_indice_carico', options)}  apiPath="components_data/gom_indice_carico_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Carico"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_indice_carico', e.value)} value={filters.gom_indice_carico.value} optionLabel="label" optionValue="value" options={filters.gom_indice_carico.options} placeholder="Carico" >
                                </Dropdown>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                </div>
                <div className="col-1 comp-grid" >
                    <div className="card  smt-1 p-2">
                        <Title title="Qualita"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                        <div className="">
                            <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_qualita', e.value)} value={filters.gom_qualita.value} optionLabel="label" optionValue="value" options={filters.gom_qualita.options} placeholder="Qualità" >
                            </Dropdown>
                        </div>
                    </div>
                    <div className="card  smt-1 p-2">
                        <DataSource onLoad={(options) => setFilterOptions('gom_indice_velocita', options)}  apiPath="components_data/gom_indice_velocita_option_list"  >
                            {
                            ({ response }) => 
                            <>
                            <Title title="Velocita"  headerClass="p-0" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                            <div className="">
                                <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'gom_indice_velocita', e.value)} value={filters.gom_indice_velocita.value} optionLabel="label" optionValue="value" options={filters.gom_indice_velocita.options} placeholder="Vel." >
                                </Dropdown>
                            </div>
                            </>
                            }
                        </DataSource>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col comp-grid" >
                    <FilterTags filterController={filterController} />
                    <div >
                        <PageBreadcrumbs />
                        <div className="page-records">
                            <DataTable 
                                lazy={true} 
                                loading={loading} 
                                selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                value={records} 
                                dataKey="gom_ean" 
                                sortField={sortBy} 
                                sortOrder={sortOrder} 
                                onSort={onSort}
                                className=" p-datatable-sm" 
                                stripedRows={true}
                                showGridlines={false} 
                                rowHover={true} 
                                responsiveLayout="stack" 
                                emptyMessage={<EmptyRecordMessage />} 
                                >
                                {/*PageComponentStart*/}
                                <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
                                <Column  field="gom_foto" header="Foto" body={GomFotoTemplate}  ></Column>
                                <Column  field="gom_ean" header="Ean" body={GomEanTemplate}  ></Column>
                                <Column  field="gom_cai" header="Gom Cai"   ></Column>
                                <Column  field="gom_disegno" header="Disegno"   ></Column>
                                <Column  field="gom_descrizione" header="Gom Descrizione"   ></Column>
                                <Column  field="gom_larghezza" header="Larghezza"   ></Column>
                                <Column  field="gom_altezza" header="Altezza"   ></Column>
                                <Column  field="gom_diametro" header="Diametro"   ></Column>
                                <Column  field="gom_indice_velocita" header="Indice Velocita"   ></Column>
                                <Column  field="gom_indice_carico" header="Indice Carico"   ></Column>
                                <Column  field="gom_classe_energetica" header="Classe Energetica"   ></Column>
                                <Column  field="gom_aderenza_bagnato" header="Aderenza Bagnato"   ></Column>
                                <Column  field="gom_rumorosita" header="Rumorosità"   ></Column>
                                <Column  field="gom_stagione" header="Stagione"   ></Column>
                                <Column  field="gom_qualita" header="Qualità"   ></Column>
                                <Column  field="gom_settore" header="Settore"   ></Column>
                                <Column  field="gom_runflat" header="Runflat"   ></Column>
                                <Column  field="gom_pfu" header="PFU"   ></Column>
                                <Column  field="gom_barrerum" header="Barre rumorosità"   ></Column>
                                <Column  field="prezzi_pre_id_disponibilita" header="Disponibilità" body={PreIdDisponibilitaTemplate} sortable={true} ></Column>
                                <Column  field="gom_peso" header="Peso"   ></Column>
                                <Column  field="prezzi_pre_prezzo" header="Prezzo" body={PrePrezzoTemplate} sortable={true} ></Column>
                                <Column  field="prezzi_pre_qta" header="Qtà" body={PreQtaTemplate} sortable={true} ></Column>
                                <Column headerStyle={{width: '2rem'}} headerClass="text-center" body={ActionButton}></Column>
                                {/*PageComponentEnd*/}
                            </DataTable>
                        </div>
                        <PageFooter />
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
	);
}
GommeListPage.defaultProps = {
	primaryKey: 'gom_ean',
	pageName: 'gomme',
	apiPath: 'gomme/index',
	routeName: 'gommelist',
	msgBeforeDelete: "Sei sicuro di voler cancellare questo record?",
	msgTitle: "Elimina record",
	msgAfterDelete: "Record cancellato con successo",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 50,
}
export default GommeListPage;
