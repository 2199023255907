import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const FornitoriAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		for_fornitore: yup.string().required().label("Ragione Sociale"),
		for_email: yup.string().email().nullable().label("Email"),
		for_password: yup.string().nullable().label("Password"),
		confirm_password: yup.string().label("Confirm Password").oneOf([yup.ref('for_password')], "Your passwords do not match"),
		for_metodo_pagamento: yup.string().nullable().label("Metodo Pagamento"),
		for_indirizzo: yup.string().nullable().label("Indirizzo"),
		for_citta: yup.string().nullable().label("Città"),
		for_provincia: yup.string().required().label("Provincia"),
		for_cap: yup.string().required().label(" Cap"),
		for_partita_iva: yup.string().nullable().label("Partita Iva"),
		for_contatto: yup.string().nullable().label("Contatto"),
		for_sdi: yup.string().nullable().label("Sdi"),
		for_pec: yup.string().nullable().label("Pec"),
		for_banca: yup.string().nullable().label("Banca"),
		for_iban: yup.string().nullable().label("Iban"),
		for_swift: yup.string().nullable().label("Swift")
	});
	
	//form default values
	const formDefaultValues = {
		for_fornitore: '', 
		for_email: '', 
		for_password: "NULL", 
		confirm_password: '', 
		for_metodo_pagamento: '', 
		for_indirizzo: "NULL", 
		for_citta: "NULL", 
		for_provincia: '', 
		for_cap: '', 
		for_partita_iva: "NULL", 
		for_contatto: "NULL", 
		for_sdi: "NULL", 
		for_pec: "NULL", 
		for_banca: "NULL", 
		for_iban: "NULL", 
		for_swift: "NULL", 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/fornitori`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="FornitoriAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Aggiungere nuova"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ragione Sociale *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_fornitore"  onChange={formik.handleChange}  value={formik.values.for_fornitore}   label="Ragione Sociale" type="text" placeholder="Ragione Sociale"        className={inputClassName(formik?.errors?.for_fornitore)} />
                                                <ErrorMessage name="for_fornitore" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Email 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_email"  onChange={formik.handleChange}  value={formik.values.for_email}   label="Email" type="email" placeholder="Email"        className={inputClassName(formik?.errors?.for_email)} />
                                                <ErrorMessage name="for_email" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Password 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="for_password" value={formik.values.for_password} onChange={formik.handleChange} label="Password" placeholder="Password"  inputClassName="w-full" toggleMask feedback className={inputClassName(formik?.errors?.for_password)} />
                                                <ErrorMessage name="for_password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Confirm Password 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="confirm_password" id="confirm_password" className={inputClassName(formik?.errors?.comfirm_password)} inputClassName="w-full" feedback={false} toggleMask  value={formik.values.confirm_password} onChange={formik.handleChange} label="conferma password" placeholder="conferma password"  />
                                                <ErrorMessage name="confirm_password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Metodo Pagamento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="for_metodo_pagamento"     optionLabel="label" optionValue="value" value={formik.values.for_metodo_pagamento} onChange={formik.handleChange} options={app.menus.forMetodoPagamento} label="Metodo Pagamento"  placeholder="Metodo Pagamento"  className={inputClassName(formik?.errors?.for_metodo_pagamento)}   />
                                                <ErrorMessage name="for_metodo_pagamento" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Indirizzo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_indirizzo"  onChange={formik.handleChange}  value={formik.values.for_indirizzo}   label="Indirizzo" type="text" placeholder=" Indirizzo"        className={inputClassName(formik?.errors?.for_indirizzo)} />
                                                <ErrorMessage name="for_indirizzo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Città 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_citta"  onChange={formik.handleChange}  value={formik.values.for_citta}   label="Città" type="text" placeholder="Città"        className={inputClassName(formik?.errors?.for_citta)} />
                                                <ErrorMessage name="for_citta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Provincia *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_provincia"  onChange={formik.handleChange}  value={formik.values.for_provincia}   label="Provincia" type="text" placeholder="accedere Provincia"        className={inputClassName(formik?.errors?.for_provincia)} />
                                                <ErrorMessage name="for_provincia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cap *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_cap"  onChange={formik.handleChange}  value={formik.values.for_cap}   label=" Cap" type="text" placeholder="accedere  Cap"        className={inputClassName(formik?.errors?.for_cap)} />
                                                <ErrorMessage name="for_cap" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Partita Iva 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_partita_iva"  onChange={formik.handleChange}  value={formik.values.for_partita_iva}   label="Partita Iva" type="text" placeholder="accedere Partita Iva"        className={inputClassName(formik?.errors?.for_partita_iva)} />
                                                <ErrorMessage name="for_partita_iva" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Contatto 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_contatto"  onChange={formik.handleChange}  value={formik.values.for_contatto}   label="Contatto" type="text" placeholder="accedere Contatto"        className={inputClassName(formik?.errors?.for_contatto)} />
                                                <ErrorMessage name="for_contatto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Sdi 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_sdi"  onChange={formik.handleChange}  value={formik.values.for_sdi}   label="Sdi" type="text" placeholder="accedere Sdi"        className={inputClassName(formik?.errors?.for_sdi)} />
                                                <ErrorMessage name="for_sdi" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pec 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_pec"  onChange={formik.handleChange}  value={formik.values.for_pec}   label="Pec" type="text" placeholder="accedere Pec"        className={inputClassName(formik?.errors?.for_pec)} />
                                                <ErrorMessage name="for_pec" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Banca 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_banca"  onChange={formik.handleChange}  value={formik.values.for_banca}   label="Banca" type="text" placeholder="accedere Banca"        className={inputClassName(formik?.errors?.for_banca)} />
                                                <ErrorMessage name="for_banca" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Iban 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_iban"  onChange={formik.handleChange}  value={formik.values.for_iban}   label="Iban" type="text" placeholder="accedere Iban"        className={inputClassName(formik?.errors?.for_iban)} />
                                                <ErrorMessage name="for_iban" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Swift 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="for_swift"  onChange={formik.handleChange}  value={formik.values.for_swift}   label="Swift" type="text" placeholder="accedere Swift"        className={inputClassName(formik?.errors?.for_swift)} />
                                                <ErrorMessage name="for_swift" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Invia" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
FornitoriAddPage.defaultProps = {
	primaryKey: 'for_id',
	pageName: 'fornitori',
	apiPath: 'fornitori/add',
	routeName: 'fornitoriadd',
	submitButtonLabel: "Invia",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Crea registro",
	msgAfterSave: "Record aggiunto con successo",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default FornitoriAddPage;
