/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/gomme",
    "label": "Catalogo",
    "icon": "pi pi-align-justify",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/fornitori",
    "label": "Fornitori",
    "icon": "pi pi-user-plus",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/clienti",
    "label": "Clienti",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/ordinitestata",
    "label": "Ordini",
    "icon": "pi pi-align-center",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/ordinitestata/carrello",
    "label": "Carrelli Abbandonati",
    "icon": "pi pi-shopping-cart",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/ricarichi",
    "label": "Ricarichi",
    "icon": "pi pi-sort-amount-up-alt",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/mappatura",
    "label": "Mappatura Listini",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/marca",
    "label": "Marca",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  }
],
	diretto: [    
{value: "S", label: "Diretto"},
	{value: "N", label: "Indiretto"}
    ],
	pagamento: [    
{value: "SEPA", label: "SEPA"},
	{value: "CONTANTI", label: "CONTANTI"},
	{value: "BONIFICO ANTICIPATO", label: "BONIFICO ANTICIPATO"},
	{value: "RIBA 30 GG FM", label: "RIBA 30 GG FM"}
    ],
	password: [    
{value: "SEPA", label: "SEPA"},
	{value: "CONTANTI", label: "CONTANTI"},
	{value: "BONIFICO ANTICIPATO", label: "BONIFICO ANTICIPATO"},
	{value: "RIBA 30 GG F M", label: "RIBA 30 GG F M"}
    ],
	forMetodoPagamento: [    
{value: "SEPA", label: "SEPA"},
	{value: "BONIFICO", label: "BONIFICO"},
	{value: "CARTA CREDITO", label: "CARTA CREDITO"},
	{value: "RIBA 30GG FM", label: "RIBA 30GG FM"}
    ],
	mapDisponibilita: [    
{value: "IMMEDIATA", label: "IMMEDIATA"},
	{value: "24H", label: "24H"},
	{value: "48H", label: "48H"},
	{value: "72H", label: "72H"},
	{value: "5 GIORNI", label: "5 Giorni"}
    ],
	attivo: [    
{value: "1", label: "Si"}
    ],
	gomClasseEnergetica: [{"label":"A","value":"A"},{"label":"B","value":"B"},{"label":"C","value":"C"},{"label":"D","value":"D"},{"label":"E","value":"E"},{"label":"F","value":"F"},{"label":"G","value":"G"},{"label":"R","value":"R"}],
	gomStagione: [{"label":"Estive","value":"Estive"},{"label":"Invernali","value":"Invernali"},{"label":"4 Stagioni","value":"4 Stagioni"}],
	gomAderenzaBagnato: [{"label":"A","value":"A"},{"label":"B","value":"B"},{"label":"C","value":"C"},{"label":"D","value":"D"},{"label":"E","value":"E"},{"label":"F","value":"F"}],
	gomRunflat: [{"label":"1","value":"1"},{"label":"127","value":"127"},{"label":"4","value":"4"},{"label":"31","value":"31"}],
	gomQualita: [{"label":"economy","value":"economy"},{"label":"quality","value":"quality"},{"label":"top","value":"top"}],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}