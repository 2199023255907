import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const MappaturaEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		nomefornitore: yup.string().required().label("Nome fornitore (senza spazi)"),
		nomefile: yup.string().required().label("Nome file CSV"),
		ftpuser: yup.string().required().label("Ftp user"),
		ftppwd: yup.string().required().label("Ftp password"),
		map_ean: yup.number().required().label("Colonna Ean"),
		map_qty: yup.number().nullable().label("Colonna quantità"),
		map_price: yup.number().nullable().label("Colonna prezzo"),
		map_disponibilita: yup.string().nullable().label("Disponibilità per ogni prodotto"),
		attivo: yup.array().min(1).label("Attivo"),
		id_fornitore: yup.string().required().label("Fornitore"),
		inizia_da_riga: yup.number().required().label("Inizia Da Riga"),
		separatore: yup.string().required().label("Separatore"),
		nome_cartella: yup.string().nullable().label("Nome Cartella come nome fornitore")
	});
	// form default values
	const formDefaultValues = {
		nomefornitore: '', 
		nomefile: '', 
		ftpuser: '', 
		ftppwd: '', 
		map_ean: '', 
		map_qty: "NULL", 
		map_price: "NULL", 
		map_disponibilita: '', 
		attivo: [], 
		id_fornitore: '', 
		inizia_da_riga: '', 
		separatore: '', 
		nome_cartella: "NULL", 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/mappatura`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="MappaturaEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome fornitore (senza spazi) *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nomefornitore"  onChange={formik.handleChange}  value={formik.values.nomefornitore}   label="Nome fornitore (senza spazi)" type="text" placeholder="accedere Nome fornitore (senza spazi)"        className={inputClassName(formik?.errors?.nomefornitore)} />
                                                <ErrorMessage name="nomefornitore" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome file CSV *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nomefile"  onChange={formik.handleChange}  value={formik.values.nomefile}   label="Nome file CSV" type="text" placeholder="Nome file"        className={inputClassName(formik?.errors?.nomefile)} />
                                                <ErrorMessage name="nomefile" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ftp user *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ftpuser"  onChange={formik.handleChange}  value={formik.values.ftpuser}   label="Ftp user" type="text" placeholder="accedere Ftp user"        className={inputClassName(formik?.errors?.ftpuser)} />
                                                <ErrorMessage name="ftpuser" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ftp password *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ftppwd"  onChange={formik.handleChange}  value={formik.values.ftppwd}   label="Ftp password" type="text" placeholder="accedere Ftp password"        className={inputClassName(formik?.errors?.ftppwd)} />
                                                <ErrorMessage name="ftppwd" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Colonna Ean *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="map_ean"  onChange={formik.handleChange}  value={formik.values.map_ean}   label="Colonna Ean" type="number" placeholder="accedere Colonna Ean"  min={0}  step="any"    className={inputClassName(formik?.errors?.map_ean)} />
                                                <ErrorMessage name="map_ean" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Colonna quantità 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="map_qty"  onChange={formik.handleChange}  value={formik.values.map_qty}   label="Colonna quantità" type="number" placeholder="accedere Colonna quantità"  min={0}  step="any"    className={inputClassName(formik?.errors?.map_qty)} />
                                                <ErrorMessage name="map_qty" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Colonna prezzo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="map_price"  onChange={formik.handleChange}  value={formik.values.map_price}   label="Colonna prezzo" type="number" placeholder="accedere Colonna prezzo"  min={0}  step="any"    className={inputClassName(formik?.errors?.map_price)} />
                                                <ErrorMessage name="map_price" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Disponibilità per ogni prodotto 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="map_disponibilita"     optionLabel="label" optionValue="value" value={formik.values.map_disponibilita} onChange={formik.handleChange} options={app.menus.mapDisponibilita} label="Disponibilità per ogni prodotto"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.map_disponibilita)}   />
                                                <ErrorMessage name="map_disponibilita" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Attivo *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                {
                                                app.menus.attivo.map((option) => {
                                                return (
                                                <div key={option.value} className="field-checkbox">
                                                    <Checkbox inputId={option.value} name="attivo" value={option.value} onChange={formik.handleChange} checked={formik.values.attivo.includes(option.value)} className={inputClassName(formik?.errors?.attivo, '')} />
                                                    <label htmlFor={option.value}>{option.label}</label>
                                                </div>
                                                )
                                                })
                                                }
                                                <ErrorMessage name="attivo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Fornitore *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/id_fornitore_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="id_fornitore"     optionLabel="label" optionValue="value" value={formik.values.id_fornitore} onChange={formik.handleChange} options={response} label="Fornitore"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.id_fornitore)}   />
                                                    <ErrorMessage name="id_fornitore" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Inizia Da Riga *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="inizia_da_riga"  onChange={formik.handleChange}  value={formik.values.inizia_da_riga}   label="Inizia Da Riga" type="number" placeholder="accedere Inizia Da Riga"  min={0}  step="any"    className={inputClassName(formik?.errors?.inizia_da_riga)} />
                                                <ErrorMessage name="inizia_da_riga" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Separatore *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="separatore"  onChange={formik.handleChange}  value={formik.values.separatore}   label="Separatore" type="text" placeholder="accedere Separatore"        className={inputClassName(formik?.errors?.separatore)} />
                                                <ErrorMessage name="separatore" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome Cartella come nome fornitore 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nome_cartella"  onChange={formik.handleChange}  value={formik.values.nome_cartella}   label="Nome Cartella come nome fornitore" type="text" placeholder="accedere Nome Cartella come nome fornitore"        className={inputClassName(formik?.errors?.nome_cartella)} />
                                                <ErrorMessage name="nome_cartella" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiorna" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
MappaturaEditPage.defaultProps = {
	primaryKey: 'id_mappa',
	pageName: 'mappatura',
	apiPath: 'mappatura/edit',
	routeName: 'mappaturaedit',
	submitButtonLabel: "Aggiorna",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Registra aggiornato correttamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default MappaturaEditPage;
