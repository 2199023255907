import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const GommeEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		gom_ean: yup.string().required().label("Gom Ean"),
		gom_disegno: yup.string().required().label("Gom Disegno"),
		gom_larghezza: yup.number().required().label("Gom Larghezza"),
		gom_altezza: yup.number().required().label("Gom Altezza"),
		gom_diametro: yup.number().required().label("Gom Diametro"),
		gom_indice_velocita: yup.string().nullable().label("Gom Indice Velocita"),
		gom_indice_carico: yup.number().nullable().label("Gom Indice Carico"),
		gom_classe_energetica: yup.string().nullable().label("Gom Classe Energetica"),
		gom_aderenza_bagnato: yup.string().nullable().label("Gom Aderenza Bagnato"),
		gom_rumorosita: yup.string().nullable().label("Gom Rumorosita"),
		gom_marca: yup.number().required().label("Gom Marca"),
		gom_stagione: yup.string().required().label("Gom Stagione"),
		gom_qualita: yup.string().required().label("Gom Qualita"),
		gom_foto: yup.string().required().label("Gom Foto"),
		gom_settore: yup.string().required().label("Gom Settore"),
		gom_pfu: yup.number().nullable().label("Gom Pfu"),
		gom_runflat: yup.number().required().label("Gom Runflat"),
		gom_barrerum: yup.string().nullable().label("Gom Barrerum"),
		gom_schede: yup.string().nullable().label("Gom Schede"),
		gom_peso: yup.number().nullable().label("Gom Peso"),
		gom_cai: yup.string().nullable().label("Gom Cai"),
		gom_descrizione: yup.string().required().label("Gom Descrizione")
	});
	// form default values
	const formDefaultValues = {
		gom_ean: '', 
		gom_disegno: '', 
		gom_larghezza: '', 
		gom_altezza: '', 
		gom_diametro: '', 
		gom_indice_velocita: "NULL", 
		gom_indice_carico: "NULL", 
		gom_classe_energetica: "NULL", 
		gom_aderenza_bagnato: "NULL", 
		gom_rumorosita: "NULL", 
		gom_marca: '', 
		gom_stagione: '', 
		gom_qualita: '', 
		gom_foto: '', 
		gom_settore: '', 
		gom_pfu: "NULL", 
		gom_runflat: '', 
		gom_barrerum: "NULL", 
		gom_schede: "NULL", 
		gom_peso: "NULL", 
		gom_cai: "NULL", 
		gom_descrizione: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/gomme`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="GommeEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Ean *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_ean"  onChange={formik.handleChange}  value={formik.values.gom_ean}   label="Gom Ean" type="text" placeholder="accedere Gom Ean"        className={inputClassName(formik?.errors?.gom_ean)} />
                                                <ErrorMessage name="gom_ean" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Disegno *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_disegno"  onChange={formik.handleChange}  value={formik.values.gom_disegno}   label="Gom Disegno" type="text" placeholder="accedere Gom Disegno"        className={inputClassName(formik?.errors?.gom_disegno)} />
                                                <ErrorMessage name="gom_disegno" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Larghezza *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_larghezza"  onChange={formik.handleChange}  value={formik.values.gom_larghezza}   label="Gom Larghezza" type="number" placeholder="accedere Gom Larghezza"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.gom_larghezza)} />
                                                <ErrorMessage name="gom_larghezza" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Altezza *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_altezza"  onChange={formik.handleChange}  value={formik.values.gom_altezza}   label="Gom Altezza" type="number" placeholder="accedere Gom Altezza"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.gom_altezza)} />
                                                <ErrorMessage name="gom_altezza" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Diametro *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_diametro"  onChange={formik.handleChange}  value={formik.values.gom_diametro}   label="Gom Diametro" type="number" placeholder="accedere Gom Diametro"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.gom_diametro)} />
                                                <ErrorMessage name="gom_diametro" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Indice Velocita 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_indice_velocita"  onChange={formik.handleChange}  value={formik.values.gom_indice_velocita}   label="Gom Indice Velocita" type="text" placeholder="accedere Gom Indice Velocita"        className={inputClassName(formik?.errors?.gom_indice_velocita)} />
                                                <ErrorMessage name="gom_indice_velocita" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Indice Carico 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_indice_carico"  onChange={formik.handleChange}  value={formik.values.gom_indice_carico}   label="Gom Indice Carico" type="number" placeholder="accedere Gom Indice Carico"  min={0}  step="any"    className={inputClassName(formik?.errors?.gom_indice_carico)} />
                                                <ErrorMessage name="gom_indice_carico" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Classe Energetica 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_classe_energetica"  onChange={formik.handleChange}  value={formik.values.gom_classe_energetica}   label="Gom Classe Energetica" type="text" placeholder="accedere Gom Classe Energetica"        className={inputClassName(formik?.errors?.gom_classe_energetica)} />
                                                <ErrorMessage name="gom_classe_energetica" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Aderenza Bagnato 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_aderenza_bagnato"  onChange={formik.handleChange}  value={formik.values.gom_aderenza_bagnato}   label="Gom Aderenza Bagnato" type="text" placeholder="accedere Gom Aderenza Bagnato"        className={inputClassName(formik?.errors?.gom_aderenza_bagnato)} />
                                                <ErrorMessage name="gom_aderenza_bagnato" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Rumorosita 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_rumorosita"  onChange={formik.handleChange}  value={formik.values.gom_rumorosita}   label="Gom Rumorosita" type="text" placeholder="accedere Gom Rumorosita"        className={inputClassName(formik?.errors?.gom_rumorosita)} />
                                                <ErrorMessage name="gom_rumorosita" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Marca *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_marca"  onChange={formik.handleChange}  value={formik.values.gom_marca}   label="Gom Marca" type="number" placeholder="accedere Gom Marca"  min={0}  step="any"    className={inputClassName(formik?.errors?.gom_marca)} />
                                                <ErrorMessage name="gom_marca" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Stagione *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_stagione"  onChange={formik.handleChange}  value={formik.values.gom_stagione}   label="Gom Stagione" type="text" placeholder="accedere Gom Stagione"        className={inputClassName(formik?.errors?.gom_stagione)} />
                                                <ErrorMessage name="gom_stagione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Qualita *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_qualita"  onChange={formik.handleChange}  value={formik.values.gom_qualita}   label="Gom Qualita" type="text" placeholder="accedere Gom Qualita"        className={inputClassName(formik?.errors?.gom_qualita)} />
                                                <ErrorMessage name="gom_qualita" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Foto *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.gom_foto)}>
                                                    <Uploader name="gom_foto" showUploadedFiles value={formik.values.gom_foto} uploadPath="fileuploader/upload/gom_foto" onChange={(paths) => formik.setFieldValue('gom_foto', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Scegli i file o trascina i file qui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="gom_foto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Settore *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_settore"  onChange={formik.handleChange}  value={formik.values.gom_settore}   label="Gom Settore" type="text" placeholder="accedere Gom Settore"        className={inputClassName(formik?.errors?.gom_settore)} />
                                                <ErrorMessage name="gom_settore" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Pfu 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_pfu"  onChange={formik.handleChange}  value={formik.values.gom_pfu}   label="Gom Pfu" type="number" placeholder="accedere Gom Pfu"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.gom_pfu)} />
                                                <ErrorMessage name="gom_pfu" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Runflat *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_runflat"  onChange={formik.handleChange}  value={formik.values.gom_runflat}   label="Gom Runflat" type="number" placeholder="accedere Gom Runflat"  min={0}  step="any"    className={inputClassName(formik?.errors?.gom_runflat)} />
                                                <ErrorMessage name="gom_runflat" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Barrerum 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_barrerum"  onChange={formik.handleChange}  value={formik.values.gom_barrerum}   label="Gom Barrerum" type="text" placeholder="accedere Gom Barrerum"        className={inputClassName(formik?.errors?.gom_barrerum)} />
                                                <ErrorMessage name="gom_barrerum" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Schede 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_schede"  onChange={formik.handleChange}  value={formik.values.gom_schede}   label="Gom Schede" type="text" placeholder="accedere Gom Schede"        className={inputClassName(formik?.errors?.gom_schede)} />
                                                <ErrorMessage name="gom_schede" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Peso 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_peso"  onChange={formik.handleChange}  value={formik.values.gom_peso}   label="Gom Peso" type="number" placeholder="accedere Gom Peso"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.gom_peso)} />
                                                <ErrorMessage name="gom_peso" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Cai 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_cai"  onChange={formik.handleChange}  value={formik.values.gom_cai}   label="Gom Cai" type="text" placeholder="accedere Gom Cai"        className={inputClassName(formik?.errors?.gom_cai)} />
                                                <ErrorMessage name="gom_cai" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gom Descrizione *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gom_descrizione"  onChange={formik.handleChange}  value={formik.values.gom_descrizione}   label="Gom Descrizione" type="text" placeholder="accedere Gom Descrizione"        className={inputClassName(formik?.errors?.gom_descrizione)} />
                                                <ErrorMessage name="gom_descrizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiorna" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
GommeEditPage.defaultProps = {
	primaryKey: 'gom_ean',
	pageName: 'gomme',
	apiPath: 'gomme/edit',
	routeName: 'gommeedit',
	submitButtonLabel: "Aggiorna",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Registra aggiornato correttamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default GommeEditPage;
