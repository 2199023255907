import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import AziendaList from 'pages/azienda/List';
import AziendaAdd from 'pages/azienda/Add';
import AziendaEdit from 'pages/azienda/Edit';
import ClientiList from 'pages/clienti/List';
import ClientiView from 'pages/clienti/View';
import ClientiAdd from 'pages/clienti/Add';
import ClientiEdit from 'pages/clienti/Edit';
import ClientiEditp from 'pages/clienti/Editp';
import FornitoriList from 'pages/fornitori/List';
import FornitoriAdd from 'pages/fornitori/Add';
import FornitoriEdit from 'pages/fornitori/Edit';
import FornitoriEditp from 'pages/fornitori/Editp';
import GommeList from 'pages/gomme/List';
import GommeView from 'pages/gomme/View';
import GommeAdd from 'pages/gomme/Add';
import GommeEdit from 'pages/gomme/Edit';
import MappaturaList from 'pages/mappatura/List';
import MappaturaView from 'pages/mappatura/View';
import MappaturaAdd from 'pages/mappatura/Add';
import MappaturaEdit from 'pages/mappatura/Edit';
import MappaturaImportat from 'pages/mappatura/Importat';
import MarcaList from 'pages/marca/List';
import MarcaView from 'pages/marca/View';
import MarcaAdd from 'pages/marca/Add';
import MarcaEdit from 'pages/marca/Edit';
import OrdinirigheList from 'pages/ordinirighe/List';
import OrdinirigheView from 'pages/ordinirighe/View';
import OrdinirigheAdd from 'pages/ordinirighe/Add';
import OrdinirigheEdit from 'pages/ordinirighe/Edit';
import OrdinitestataList from 'pages/ordinitestata/List';
import OrdinitestataView from 'pages/ordinitestata/View';
import OrdinitestataAdd from 'pages/ordinitestata/Add';
import OrdinitestataEdit from 'pages/ordinitestata/Edit';
import OrdinitestataCarrello from 'pages/ordinitestata/Carrello';
import RicarichiList from 'pages/ricarichi/List';
import RicarichiAdd from 'pages/ricarichi/Add';
import RicarichiEdit from 'pages/ricarichi/Edit';
import SessionsList from 'pages/sessions/List';
import SessionsView from 'pages/sessions/View';
import SessionsAdd from 'pages/sessions/Add';
import SessionsEdit from 'pages/sessions/Edit';
import TyrelogList from 'pages/tyrelog/List';
import TyrelogView from 'pages/tyrelog/View';
import TyrelogAdd from 'pages/tyrelog/Add';
import TyrelogEdit from 'pages/tyrelog/Edit';
import UtentiList from 'pages/utenti/List';
import UtentiView from 'pages/utenti/View';
import UtentiAdd from 'pages/utenti/Add';
import UtentiEdit from 'pages/utenti/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* azienda pages routes */}
				<Route path="/azienda" element={<AziendaList />} />
				<Route path="/azienda/:fieldName/:fieldValue" element={<AziendaList />} />
				<Route path="/azienda/index/:fieldName/:fieldValue" element={<AziendaList />} />
				<Route path="/azienda/add" element={<AziendaAdd />} />
				<Route path="/azienda/edit/:pageid" element={<AziendaEdit />} />

				{/* clienti pages routes */}
				<Route path="/clienti" element={<ClientiList />} />
				<Route path="/clienti/:fieldName/:fieldValue" element={<ClientiList />} />
				<Route path="/clienti/index/:fieldName/:fieldValue" element={<ClientiList />} />
				<Route path="/clienti/view/:pageid" element={<ClientiView />} />
				<Route path="/clienti/add" element={<ClientiAdd />} />
				<Route path="/clienti/edit/:pageid" element={<ClientiEdit />} />
				<Route path="/clienti/editp/:pageid" element={<ClientiEditp />} />

				{/* fornitori pages routes */}
				<Route path="/fornitori" element={<FornitoriList />} />
				<Route path="/fornitori/:fieldName/:fieldValue" element={<FornitoriList />} />
				<Route path="/fornitori/index/:fieldName/:fieldValue" element={<FornitoriList />} />
				<Route path="/fornitori/add" element={<FornitoriAdd />} />
				<Route path="/fornitori/edit/:pageid" element={<FornitoriEdit />} />
				<Route path="/fornitori/editp/:pageid" element={<FornitoriEditp />} />

				{/* gomme pages routes */}
				<Route path="/gomme" element={<GommeList />} />
				<Route path="/gomme/:fieldName/:fieldValue" element={<GommeList />} />
				<Route path="/gomme/index/:fieldName/:fieldValue" element={<GommeList />} />
				<Route path="/gomme/view/:pageid" element={<GommeView />} />
				<Route path="/gomme/add" element={<GommeAdd />} />
				<Route path="/gomme/edit/:pageid" element={<GommeEdit />} />

				{/* mappatura pages routes */}
				<Route path="/mappatura" element={<MappaturaList />} />
				<Route path="/mappatura/:fieldName/:fieldValue" element={<MappaturaList />} />
				<Route path="/mappatura/index/:fieldName/:fieldValue" element={<MappaturaList />} />
				<Route path="/mappatura/view/:pageid" element={<MappaturaView />} />
				<Route path="/mappatura/add" element={<MappaturaAdd />} />
				<Route path="/mappatura/edit/:pageid" element={<MappaturaEdit />} />
				<Route path="/mappatura/importat" element={<MappaturaImportat />} />
				<Route path="/mappatura/importat/:fieldName/:fieldValue" element={<MappaturaImportat />} />

				{/* marca pages routes */}
				<Route path="/marca" element={<MarcaList />} />
				<Route path="/marca/:fieldName/:fieldValue" element={<MarcaList />} />
				<Route path="/marca/index/:fieldName/:fieldValue" element={<MarcaList />} />
				<Route path="/marca/view/:pageid" element={<MarcaView />} />
				<Route path="/marca/add" element={<MarcaAdd />} />
				<Route path="/marca/edit/:pageid" element={<MarcaEdit />} />

				{/* ordinirighe pages routes */}
				<Route path="/ordinirighe" element={<OrdinirigheList />} />
				<Route path="/ordinirighe/:fieldName/:fieldValue" element={<OrdinirigheList />} />
				<Route path="/ordinirighe/index/:fieldName/:fieldValue" element={<OrdinirigheList />} />
				<Route path="/ordinirighe/view/:pageid" element={<OrdinirigheView />} />
				<Route path="/ordinirighe/add" element={<OrdinirigheAdd />} />
				<Route path="/ordinirighe/edit/:pageid" element={<OrdinirigheEdit />} />

				{/* ordinitestata pages routes */}
				<Route path="/ordinitestata" element={<OrdinitestataList />} />
				<Route path="/ordinitestata/:fieldName/:fieldValue" element={<OrdinitestataList />} />
				<Route path="/ordinitestata/index/:fieldName/:fieldValue" element={<OrdinitestataList />} />
				<Route path="/ordinitestata/view/:pageid" element={<OrdinitestataView />} />
				<Route path="/ordinitestata/add" element={<OrdinitestataAdd />} />
				<Route path="/ordinitestata/edit/:pageid" element={<OrdinitestataEdit />} />
				<Route path="/ordinitestata/carrello" element={<OrdinitestataCarrello />} />
				<Route path="/ordinitestata/carrello/:fieldName/:fieldValue" element={<OrdinitestataCarrello />} />

				{/* ricarichi pages routes */}
				<Route path="/ricarichi" element={<RicarichiList />} />
				<Route path="/ricarichi/:fieldName/:fieldValue" element={<RicarichiList />} />
				<Route path="/ricarichi/index/:fieldName/:fieldValue" element={<RicarichiList />} />
				<Route path="/ricarichi/add" element={<RicarichiAdd />} />
				<Route path="/ricarichi/edit/:pageid" element={<RicarichiEdit />} />

				{/* sessions pages routes */}
				<Route path="/sessions" element={<SessionsList />} />
				<Route path="/sessions/:fieldName/:fieldValue" element={<SessionsList />} />
				<Route path="/sessions/index/:fieldName/:fieldValue" element={<SessionsList />} />
				<Route path="/sessions/view/:pageid" element={<SessionsView />} />
				<Route path="/sessions/add" element={<SessionsAdd />} />
				<Route path="/sessions/edit/:pageid" element={<SessionsEdit />} />

				{/* tyrelog pages routes */}
				<Route path="/tyrelog" element={<TyrelogList />} />
				<Route path="/tyrelog/:fieldName/:fieldValue" element={<TyrelogList />} />
				<Route path="/tyrelog/index/:fieldName/:fieldValue" element={<TyrelogList />} />
				<Route path="/tyrelog/view/:pageid" element={<TyrelogView />} />
				<Route path="/tyrelog/add" element={<TyrelogAdd />} />
				<Route path="/tyrelog/edit/:pageid" element={<TyrelogEdit />} />

				{/* utenti pages routes */}
				<Route path="/utenti" element={<UtentiList />} />
				<Route path="/utenti/:fieldName/:fieldValue" element={<UtentiList />} />
				<Route path="/utenti/index/:fieldName/:fieldValue" element={<UtentiList />} />
				<Route path="/utenti/view/:pageid" element={<UtentiView />} />
				<Route path="/utenti/add" element={<UtentiAdd />} />
				<Route path="/utenti/edit/:pageid" element={<UtentiEdit />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
