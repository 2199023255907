import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const RicarichiEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		ric_da: yup.number().required().label("Da €."),
		ric_a: yup.number().required().label("A. €."),
		ric_percentuale: yup.number().required().label("Ricarico Percentuale"),
		ric_valore: yup.number().required().label("Ricarico Valore")
	});
	// form default values
	const formDefaultValues = {
		ric_da: '', 
		ric_a: '', 
		ric_percentuale: '', 
		ric_valore: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/ricarichi`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="RicarichiEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Da €. *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ric_da"  onChange={formik.handleChange}  value={formik.values.ric_da}   label="Da €." type="number" placeholder="accedere Da €."  min={0}  step={0.01}    className={inputClassName(formik?.errors?.ric_da)} />
                                                <ErrorMessage name="ric_da" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                A. €. *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ric_a"  onChange={formik.handleChange}  value={formik.values.ric_a}   label="A. €." type="number" placeholder="accedere A. €."  min={0}  step={0.01}    className={inputClassName(formik?.errors?.ric_a)} />
                                                <ErrorMessage name="ric_a" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ricarico Percentuale *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ric_percentuale"  onChange={formik.handleChange}  value={formik.values.ric_percentuale}   label="Ricarico Percentuale" type="number" placeholder="accedere Ricarico Percentuale"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.ric_percentuale)} />
                                                <ErrorMessage name="ric_percentuale" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ricarico Valore *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ric_valore"  onChange={formik.handleChange}  value={formik.values.ric_valore}   label="Ricarico Valore" type="number" placeholder="accedere Ricarico Valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.ric_valore)} />
                                                <ErrorMessage name="ric_valore" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Update" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
RicarichiEditPage.defaultProps = {
	primaryKey: 'ric_id',
	pageName: 'ricarichi',
	apiPath: 'ricarichi/edit',
	routeName: 'ricarichiedit',
	submitButtonLabel: "Update",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record modificato",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default RicarichiEditPage;
