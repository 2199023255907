import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useListPage from 'hooks/useListPage';
const MappaturaListPage = (props) => {
		const app = useApp();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	function ActionButton(data){
		const items = [
		{
			label: "View",
			command: (event) => { app.navigate(`/mappatura/view/${data.id_mappa}`) },
			icon: "pi pi-eye"
		},
		{
			label: "Edit",
			command: (event) => { app.navigate(`/mappatura/edit/${data.id_mappa}`) },
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id_mappa) },
			icon: "pi pi-trash"
		}
	]
		return (<Menubar className="p-0 " model={items} />);
	}
	function IdMappaTemplate(data){
		if(data){
			return (
				<Link to={`/mappatura/view/${data.id_mappa}`}> { data.id_mappa }</Link>
			);
		}
	}
	function ForFornitoreTemplate(data){
		if(data){
			return (
				<>{ data.fornitori_for_fornitore }</>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Caricamento in corso...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Nessun record trovato
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Elimina selezionato"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Record <b>{ recordsPosition } di { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="MappaturaListPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col-4 " >
                    <Title title="Mappatura"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <div className="col-sm-4 col-md-4 col-3 comp-grid" >
                    <Link to={`/`}>
                        <Button label="Importa prezzi"  type="button" className="p-button w-full bg-pink-100 "  />
                        </Link>
                    </div>
                    <div className="col-fixed " >
                        <Link to={`/mappatura/add`}>
                            <Button label="Aggiungere nuova" icon="pi pi-plus" type="button" className="p-button w-full bg-primary "  />
                            </Link>
                        </div>
                        <div className="col-3 " >
                            <span className="p-input-icon-left w-full">
                            <i className="pi pi-search" />
                            <InputText placeholder="Ricerca" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            }
            <section className="page-section " >
                <div className="container-fluid">
                    <div className="grid ">
                        <div className="col comp-grid" >
                            <FilterTags filterController={filterController} />
                            <div >
                                <PageBreadcrumbs />
                                <div className="page-records">
                                    <DataTable 
                                        lazy={true} 
                                        loading={loading} 
                                        selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                        value={records} 
                                        dataKey="id_mappa" 
                                        sortField={sortBy} 
                                        sortOrder={sortOrder} 
                                        onSort={onSort}
                                        className=" p-datatable-sm" 
                                        stripedRows={true}
                                        showGridlines={false} 
                                        rowHover={true} 
                                        responsiveLayout="stack" 
                                        emptyMessage={<EmptyRecordMessage />} 
                                        >
                                        {/*PageComponentStart*/}
                                        <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
                                        <Column  field="id_mappa" header="Id Mappa" body={IdMappaTemplate}  ></Column>
                                        <Column  field="nomefile" header="Nomefile"   ></Column>
                                        <Column  field="attivo" header="Attivo"   ></Column>
                                        <Column  field="map_disponibilita" header="Map Disponibilita"   ></Column>
                                        <Column  field="ordine" header="Ordine"   ></Column>
                                        <Column  field="fornitori_for_fornitore" header="Fornitore" body={ForFornitoreTemplate}  ></Column>
                                        <Column  field="nome_cartella" header="Nome Cartella"   ></Column>
                                        <Column headerStyle={{width: 'auto'}} headerClass="text-center" body={ActionButton}></Column>
                                        {/*PageComponentEnd*/}
                                    </DataTable>
                                </div>
                                <PageFooter />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
	);
}
MappaturaListPage.defaultProps = {
	primaryKey: 'id_mappa',
	pageName: 'mappatura',
	apiPath: 'mappatura/index',
	routeName: 'mappaturalist',
	msgBeforeDelete: "Sei sicuro di voler cancellare questo record?",
	msgTitle: "Elimina record",
	msgAfterDelete: "Record cancellato con successo",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default MappaturaListPage;
