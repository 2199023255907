import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const OrdinirigheAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		id_ordine: yup.number().required().label("Id Ordine"),
		ean_gomma: yup.string().required().label("Ean Gomma"),
		quantita_gomma: yup.number().required().label("Quantita Gomma"),
		prezzo_gomma: yup.number().required().label("Prezzo Gomma"),
		pfu: yup.number().required().label("Pfu"),
		id_fornitore: yup.number().required().label("Id Fornitore"),
		note: yup.string().nullable().label("Note"),
		spedizione: yup.string().nullable().label("Spedizione"),
		pagamento: yup.string().required().label("Pagamento"),
		centri_di_montaggio: yup.number().required().label("Centri Di Montaggio"),
		costo_spedizione: yup.number().required().label("Costo Spedizione")
	});
	
	//form default values
	const formDefaultValues = {
		id_ordine: '', 
		ean_gomma: '', 
		quantita_gomma: '', 
		prezzo_gomma: '', 
		pfu: '', 
		id_fornitore: '', 
		note: "NULL", 
		spedizione: "'Consegna'", 
		pagamento: "'Condizioni concordate'", 
		centri_di_montaggio: "0", 
		costo_spedizione: "0.00", 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/ordinirighe`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="OrdinirigheAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Aggiungere nuova"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Ordine *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_ordine"  onChange={formik.handleChange}  value={formik.values.id_ordine}   label="Id Ordine" type="number" placeholder="accedere Id Ordine"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_ordine)} />
                                                <ErrorMessage name="id_ordine" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ean Gomma *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ean_gomma"  onChange={formik.handleChange}  value={formik.values.ean_gomma}   label="Ean Gomma" type="text" placeholder="accedere Ean Gomma"        className={inputClassName(formik?.errors?.ean_gomma)} />
                                                <ErrorMessage name="ean_gomma" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Quantita Gomma *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="quantita_gomma"  onChange={formik.handleChange}  value={formik.values.quantita_gomma}   label="Quantita Gomma" type="number" placeholder="accedere Quantita Gomma"  min={0}  step="any"    className={inputClassName(formik?.errors?.quantita_gomma)} />
                                                <ErrorMessage name="quantita_gomma" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prezzo Gomma *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prezzo_gomma"  onChange={formik.handleChange}  value={formik.values.prezzo_gomma}   label="Prezzo Gomma" type="number" placeholder="accedere Prezzo Gomma"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.prezzo_gomma)} />
                                                <ErrorMessage name="prezzo_gomma" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pfu *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="pfu"  onChange={formik.handleChange}  value={formik.values.pfu}   label="Pfu" type="number" placeholder="accedere Pfu"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.pfu)} />
                                                <ErrorMessage name="pfu" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Fornitore *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_fornitore"  onChange={formik.handleChange}  value={formik.values.id_fornitore}   label="Id Fornitore" type="number" placeholder="accedere Id Fornitore"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_fornitore)} />
                                                <ErrorMessage name="id_fornitore" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Note 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="note"  onChange={formik.handleChange}  value={formik.values.note}   label="Note" type="text" placeholder="accedere Note"        className={inputClassName(formik?.errors?.note)} />
                                                <ErrorMessage name="note" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Spedizione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="spedizione"  onChange={formik.handleChange}  value={formik.values.spedizione}   label="Spedizione" type="text" placeholder="accedere Spedizione"        className={inputClassName(formik?.errors?.spedizione)} />
                                                <ErrorMessage name="spedizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pagamento *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="pagamento"  onChange={formik.handleChange}  value={formik.values.pagamento}   label="Pagamento" type="text" placeholder="accedere Pagamento"        className={inputClassName(formik?.errors?.pagamento)} />
                                                <ErrorMessage name="pagamento" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Centri Di Montaggio *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="centri_di_montaggio"  onChange={formik.handleChange}  value={formik.values.centri_di_montaggio}   label="Centri Di Montaggio" type="number" placeholder="accedere Centri Di Montaggio"  min={0}  step="any"    className={inputClassName(formik?.errors?.centri_di_montaggio)} />
                                                <ErrorMessage name="centri_di_montaggio" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Costo Spedizione *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="costo_spedizione"  onChange={formik.handleChange}  value={formik.values.costo_spedizione}   label="Costo Spedizione" type="number" placeholder="accedere Costo Spedizione"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.costo_spedizione)} />
                                                <ErrorMessage name="costo_spedizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Submit" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
OrdinirigheAddPage.defaultProps = {
	primaryKey: 'id_riga',
	pageName: 'ordinirighe',
	apiPath: 'ordinirighe/add',
	routeName: 'ordinirigheadd',
	submitButtonLabel: "Submit",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Crea registro",
	msgAfterSave: "Record aggiunto",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default OrdinirigheAddPage;
