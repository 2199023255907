import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const ClientiEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		ragione_sociale: yup.string().required().label("Ragione Sociale"),
		indirizzo: yup.string().nullable().label("Indirizzo"),
		cap: yup.string().nullable().label("Cap"),
		citta: yup.string().nullable().label("Città"),
		provincia: yup.string().nullable().label("Provincia"),
		partita_iva: yup.string().nullable().label("Partita Iva"),
		codice_fiscale: yup.string().nullable().label("Codice Fiscale"),
		telefono_ufficio: yup.string().nullable().label("Telefono Ufficio"),
		telefono_cellulare: yup.string().nullable().label("Telefono Cellulare"),
		email: yup.string().email().nullable().label("Email"),
		pec: yup.string().nullable().label("Pec"),
		sdi: yup.string().nullable().label("Sdi"),
		sconto: yup.number().nullable().label("Sconto"),
		pagamento: yup.string().nullable().label("Pagamento"),
		iban: yup.string().nullable().label("Iban"),
		bic_swift: yup.string().nullable().label("Bic Swift"),
		diretto: yup.string().nullable().label("Diretto")
	});
	// form default values
	const formDefaultValues = {
		ragione_sociale: '', 
		indirizzo: '', 
		cap: '', 
		citta: '', 
		provincia: '', 
		partita_iva: '', 
		codice_fiscale: '', 
		telefono_ufficio: '', 
		telefono_cellulare: '', 
		email: '', 
		pec: '', 
		sdi: '', 
		sconto: "0", 
		pagamento: '', 
		iban: '', 
		bic_swift: '', 
		diretto: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/clienti`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="ClientiEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ragione Sociale *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ragione_sociale"  onChange={formik.handleChange}  value={formik.values.ragione_sociale}   label="Ragione Sociale" type="text" placeholder="accedere Ragione Sociale"        className={inputClassName(formik?.errors?.ragione_sociale)} />
                                                <ErrorMessage name="ragione_sociale" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Indirizzo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="indirizzo"  onChange={formik.handleChange}  value={formik.values.indirizzo}   label="Indirizzo" type="text" placeholder="accedere Indirizzo"        className={inputClassName(formik?.errors?.indirizzo)} />
                                                <ErrorMessage name="indirizzo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cap 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cap"  onChange={formik.handleChange}  value={formik.values.cap}   label="Cap" type="text" placeholder="accedere Cap"        className={inputClassName(formik?.errors?.cap)} />
                                                <ErrorMessage name="cap" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Città 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="citta"  onChange={formik.handleChange}  value={formik.values.citta}   label="Città" type="text" placeholder="accedere Città"        className={inputClassName(formik?.errors?.citta)} />
                                                <ErrorMessage name="citta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Provincia 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="provincia"  onChange={formik.handleChange}  value={formik.values.provincia}   label="Provincia" type="text" placeholder="accedere Provincia"        className={inputClassName(formik?.errors?.provincia)} />
                                                <ErrorMessage name="provincia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Partita Iva 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="partita_iva"  onChange={formik.handleChange}  value={formik.values.partita_iva}   label="Partita Iva" type="text" placeholder="accedere Partita Iva"        className={inputClassName(formik?.errors?.partita_iva)} />
                                                <ErrorMessage name="partita_iva" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Codice Fiscale 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="codice_fiscale"  onChange={formik.handleChange}  value={formik.values.codice_fiscale}   label="Codice Fiscale" type="text" placeholder="accedere Codice Fiscale"        className={inputClassName(formik?.errors?.codice_fiscale)} />
                                                <ErrorMessage name="codice_fiscale" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Telefono Ufficio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="telefono_ufficio"  onChange={formik.handleChange}  value={formik.values.telefono_ufficio}   label="Telefono Ufficio" type="text" placeholder="accedere Telefono Ufficio"        className={inputClassName(formik?.errors?.telefono_ufficio)} />
                                                <ErrorMessage name="telefono_ufficio" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Telefono Cellulare 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="telefono_cellulare"  onChange={formik.handleChange}  value={formik.values.telefono_cellulare}   label="Telefono Cellulare" type="text" placeholder="accedere Telefono Cellulare"        className={inputClassName(formik?.errors?.telefono_cellulare)} />
                                                <ErrorMessage name="telefono_cellulare" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Email 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="email"  onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="accedere Email"        className={inputClassName(formik?.errors?.email)} />
                                                <ErrorMessage name="email" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pec 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="pec"  onChange={formik.handleChange}  value={formik.values.pec}   label="Pec" type="text" placeholder="accedere Pec"        className={inputClassName(formik?.errors?.pec)} />
                                                <ErrorMessage name="pec" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Sdi 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="sdi"  onChange={formik.handleChange}  value={formik.values.sdi}   label="Sdi" type="text" placeholder="accedere Sdi"        className={inputClassName(formik?.errors?.sdi)} />
                                                <ErrorMessage name="sdi" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Sconto 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="sconto"  onChange={formik.handleChange}  value={formik.values.sconto}   label="Sconto" type="number" placeholder="accedere Sconto"  min={0}  step="any"    className={inputClassName(formik?.errors?.sconto)} />
                                                <ErrorMessage name="sconto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pagamento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="pagamento"     optionLabel="label" optionValue="value" value={formik.values.pagamento} onChange={formik.handleChange} options={app.menus.pagamento} label="Pagamento"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.pagamento)}   />
                                                <ErrorMessage name="pagamento" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Iban 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="iban"  onChange={formik.handleChange}  value={formik.values.iban}   label="Iban" type="text" placeholder="accedere Iban"        className={inputClassName(formik?.errors?.iban)} />
                                                <ErrorMessage name="iban" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Bic Swift 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="bic_swift"  onChange={formik.handleChange}  value={formik.values.bic_swift}   label="Bic Swift" type="text" placeholder="accedere Bic Swift"        className={inputClassName(formik?.errors?.bic_swift)} />
                                                <ErrorMessage name="bic_swift" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Diretto 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="diretto"     optionLabel="label" optionValue="value" value={formik.values.diretto} onChange={formik.handleChange} options={app.menus.diretto} label="Diretto"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.diretto)}   />
                                                <ErrorMessage name="diretto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiorna" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
ClientiEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'clienti',
	apiPath: 'clienti/edit',
	routeName: 'clientiedit',
	submitButtonLabel: "Aggiorna",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Registra aggiornato correttamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ClientiEditPage;
