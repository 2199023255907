import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const OrdinitestataEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		id_cliente: yup.number().required().label("Id Cliente"),
		data_ordine: yup.string().required().label("Data Ordine"),
		indirizzo_spedizione: yup.string().nullable().label("Indirizzo Spedizione"),
		cap_spedizione: yup.string().nullable().label("Cap Spedizione"),
		provincia_spedizione: yup.string().nullable().label("Provincia Spedizione"),
		citta_spedizione: yup.string().nullable().label("Citta Spedizione"),
		tracking: yup.string().nullable().label("Tracking"),
		fattura: yup.string().nullable().label("Fattura"),
		data_evasione: yup.string().required().label("Data Evasione"),
		evaso: yup.number().required().label("Evaso"),
		inviato: yup.number().required().label("Inviato")
	});
	// form default values
	const formDefaultValues = {
		id_cliente: '', 
		data_ordine: new Date(), 
		indirizzo_spedizione: "NULL", 
		cap_spedizione: "NULL", 
		provincia_spedizione: "NULL", 
		citta_spedizione: "NULL", 
		tracking: "NULL", 
		fattura: "NULL", 
		data_evasione: new Date(), 
		evaso: "0", 
		inviato: "0", 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/ordinitestata`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="OrdinitestataEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Cliente *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_cliente"  onChange={formik.handleChange}  value={formik.values.id_cliente}   label="Id Cliente" type="number" placeholder="accedere Id Cliente"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_cliente)} />
                                                <ErrorMessage name="id_cliente" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Ordine *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_ordine" showButtonBar className={inputClassName(formik?.errors?.data_ordine)} dateFormat="yy-mm-dd" value={formik.values.data_ordine} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_ordine" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Indirizzo Spedizione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="indirizzo_spedizione"  onChange={formik.handleChange}  value={formik.values.indirizzo_spedizione}   label="Indirizzo Spedizione" type="text" placeholder="accedere Indirizzo Spedizione"        className={inputClassName(formik?.errors?.indirizzo_spedizione)} />
                                                <ErrorMessage name="indirizzo_spedizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cap Spedizione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cap_spedizione"  onChange={formik.handleChange}  value={formik.values.cap_spedizione}   label="Cap Spedizione" type="text" placeholder="accedere Cap Spedizione"        className={inputClassName(formik?.errors?.cap_spedizione)} />
                                                <ErrorMessage name="cap_spedizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Provincia Spedizione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="provincia_spedizione"  onChange={formik.handleChange}  value={formik.values.provincia_spedizione}   label="Provincia Spedizione" type="text" placeholder="accedere Provincia Spedizione"        className={inputClassName(formik?.errors?.provincia_spedizione)} />
                                                <ErrorMessage name="provincia_spedizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Citta Spedizione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="citta_spedizione"  onChange={formik.handleChange}  value={formik.values.citta_spedizione}   label="Citta Spedizione" type="text" placeholder="accedere Citta Spedizione"        className={inputClassName(formik?.errors?.citta_spedizione)} />
                                                <ErrorMessage name="citta_spedizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tracking 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="tracking"  onChange={formik.handleChange}  value={formik.values.tracking}   label="Tracking" type="text" placeholder="accedere Tracking"        className={inputClassName(formik?.errors?.tracking)} />
                                                <ErrorMessage name="tracking" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Fattura 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="fattura"  onChange={formik.handleChange}  value={formik.values.fattura}   label="Fattura" type="text" placeholder="accedere Fattura"        className={inputClassName(formik?.errors?.fattura)} />
                                                <ErrorMessage name="fattura" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Evasione *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_evasione" showButtonBar className={inputClassName(formik?.errors?.data_evasione)} dateFormat="yy-mm-dd" value={formik.values.data_evasione} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_evasione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Evaso *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="evaso"  onChange={formik.handleChange}  value={formik.values.evaso}   label="Evaso" type="number" placeholder="accedere Evaso"  min={0}  step="any"    className={inputClassName(formik?.errors?.evaso)} />
                                                <ErrorMessage name="evaso" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Inviato *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="inviato"  onChange={formik.handleChange}  value={formik.values.inviato}   label="Inviato" type="number" placeholder="accedere Inviato"  min={0}  step="any"    className={inputClassName(formik?.errors?.inviato)} />
                                                <ErrorMessage name="inviato" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Update" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
OrdinitestataEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'ordinitestata',
	apiPath: 'ordinitestata/edit',
	routeName: 'ordinitestataedit',
	submitButtonLabel: "Update",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record modificato",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default OrdinitestataEditPage;
