import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { CheckDuplicate } from 'components/CheckDuplicate';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const AziendaEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		azi_ragione_sociale: yup.string().required().label("Azi Ragione Sociale"),
		azi_denominazione: yup.string().required().label("Azi Denominazione"),
		azi_indirizzo: yup.string().nullable().label("Azi Indirizzo"),
		azi_cap: yup.string().nullable().label("Azi Cap"),
		azi_citta: yup.string().nullable().label("Azi Citta"),
		azi_provincia: yup.string().nullable().label("Azi Provincia"),
		azi_telefono: yup.string().nullable().label("Azi Telefono"),
		azi_cellulare: yup.string().nullable().label("Azi Cellulare"),
		azi_referente: yup.string().nullable().label("Azi Referente"),
		azi_facebook: yup.string().nullable().label("Azi Facebook"),
		azi_logo: yup.string().nullable().label("Azi Logo"),
		azi_instagram: yup.string().nullable().label("Azi Instagram"),
		azi_whatsapp: yup.string().nullable().label("Azi Whatsapp"),
		azi_abilitato: yup.string().required().label("Azi Abilitato"),
		azi_notifica_wa: yup.string().nullable().label("Azi Notifica Wa"),
		azi_notifica_email: yup.string().nullable().label("Azi Notifica Email"),
		azi_email_ordine1: yup.string().email().nullable().label("Azi Email Ordine1"),
		azi_email_ordine2: yup.string().email().nullable().label("Azi Email Ordine2"),
		azi_email_ordine3: yup.string().email().nullable().label("Azi Email Ordine3"),
		modalita_pagamento: yup.string().nullable().label("Modalita Pagamento"),
		azi_partita_iva: yup.string().nullable().label("Azi Partita Iva"),
		azi_banca: yup.string().nullable().label("Azi Banca"),
		azi_iban: yup.string().nullable().label("Azi Iban"),
		azi_swift: yup.string().nullable().label("Azi Swift"),
		azi_sdi: yup.string().nullable().label("Azi Sdi"),
		azi_pec: yup.string().nullable().label("Azi Pec"),
		azi_ruolo: yup.string().nullable().label("Azi Ruolo")
	});
	// form default values
	const formDefaultValues = {
		azi_ragione_sociale: '', 
		azi_denominazione: '', 
		azi_indirizzo: '', 
		azi_cap: '', 
		azi_citta: '', 
		azi_provincia: '', 
		azi_telefono: '', 
		azi_cellulare: '', 
		azi_referente: '', 
		azi_facebook: '', 
		azi_logo: '', 
		azi_instagram: '', 
		azi_whatsapp: '', 
		azi_abilitato: '', 
		azi_notifica_wa: "'N'", 
		azi_notifica_email: "'N'", 
		azi_email_ordine1: "NULL", 
		azi_email_ordine2: "NULL", 
		azi_email_ordine3: "NULL", 
		modalita_pagamento: "'Come concordato'", 
		azi_partita_iva: '', 
		azi_banca: '', 
		azi_iban: '', 
		azi_swift: '', 
		azi_sdi: "NULL", 
		azi_pec: "NULL", 
		azi_ruolo: "azienda", 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/azienda`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="AziendaEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Ragione Sociale *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.azi_ragione_sociale} apiPath="components_data/azienda_azi_ragione_sociale_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="azi_ragione_sociale" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.azi_ragione_sociale}   label="Azi Ragione Sociale" type="text" placeholder="accedere Azi Ragione Sociale"        className={inputClassName(formik?.errors?.azi_ragione_sociale)} />
                                                <ErrorMessage name="azi_ragione_sociale" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">Non disponibile</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Denominazione *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.azi_denominazione} apiPath="components_data/azienda_azi_denominazione_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="azi_denominazione" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.azi_denominazione}   label="Azi Denominazione" type="text" placeholder="accedere Azi Denominazione"        className={inputClassName(formik?.errors?.azi_denominazione)} />
                                                <ErrorMessage name="azi_denominazione" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">Non disponibile</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Indirizzo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_indirizzo"  onChange={formik.handleChange}  value={formik.values.azi_indirizzo}   label="Azi Indirizzo" type="text" placeholder="accedere Azi Indirizzo"        className={inputClassName(formik?.errors?.azi_indirizzo)} />
                                                <ErrorMessage name="azi_indirizzo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Cap 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_cap"  onChange={formik.handleChange}  value={formik.values.azi_cap}   label="Azi Cap" type="text" placeholder="accedere Azi Cap"        className={inputClassName(formik?.errors?.azi_cap)} />
                                                <ErrorMessage name="azi_cap" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Citta 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_citta"  onChange={formik.handleChange}  value={formik.values.azi_citta}   label="Azi Citta" type="text" placeholder="accedere Azi Citta"        className={inputClassName(formik?.errors?.azi_citta)} />
                                                <ErrorMessage name="azi_citta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Provincia 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_provincia"  onChange={formik.handleChange}  value={formik.values.azi_provincia}   label="Azi Provincia" type="text" placeholder="accedere Azi Provincia"        className={inputClassName(formik?.errors?.azi_provincia)} />
                                                <ErrorMessage name="azi_provincia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Telefono 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_telefono"  onChange={formik.handleChange}  value={formik.values.azi_telefono}   label="Azi Telefono" type="text" placeholder="accedere Azi Telefono"        className={inputClassName(formik?.errors?.azi_telefono)} />
                                                <ErrorMessage name="azi_telefono" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Cellulare 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_cellulare"  onChange={formik.handleChange}  value={formik.values.azi_cellulare}   label="Azi Cellulare" type="text" placeholder="accedere Azi Cellulare"        className={inputClassName(formik?.errors?.azi_cellulare)} />
                                                <ErrorMessage name="azi_cellulare" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Referente 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_referente"  onChange={formik.handleChange}  value={formik.values.azi_referente}   label="Azi Referente" type="text" placeholder="accedere Azi Referente"        className={inputClassName(formik?.errors?.azi_referente)} />
                                                <ErrorMessage name="azi_referente" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Facebook 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_facebook"  onChange={formik.handleChange}  value={formik.values.azi_facebook}   label="Azi Facebook" type="text" placeholder="accedere Azi Facebook"        className={inputClassName(formik?.errors?.azi_facebook)} />
                                                <ErrorMessage name="azi_facebook" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Logo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_logo"  onChange={formik.handleChange}  value={formik.values.azi_logo}   label="Azi Logo" type="text" placeholder="accedere Azi Logo"        className={inputClassName(formik?.errors?.azi_logo)} />
                                                <ErrorMessage name="azi_logo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Instagram 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_instagram"  onChange={formik.handleChange}  value={formik.values.azi_instagram}   label="Azi Instagram" type="text" placeholder="accedere Azi Instagram"        className={inputClassName(formik?.errors?.azi_instagram)} />
                                                <ErrorMessage name="azi_instagram" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Whatsapp 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_whatsapp"  onChange={formik.handleChange}  value={formik.values.azi_whatsapp}   label="Azi Whatsapp" type="text" placeholder="accedere Azi Whatsapp"        className={inputClassName(formik?.errors?.azi_whatsapp)} />
                                                <ErrorMessage name="azi_whatsapp" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Abilitato *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_abilitato"  onChange={formik.handleChange}  value={formik.values.azi_abilitato}   label="Azi Abilitato" type="text" placeholder="accedere Azi Abilitato"        className={inputClassName(formik?.errors?.azi_abilitato)} />
                                                <ErrorMessage name="azi_abilitato" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Notifica Wa 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_notifica_wa"  onChange={formik.handleChange}  value={formik.values.azi_notifica_wa}   label="Azi Notifica Wa" type="text" placeholder="accedere Azi Notifica Wa"        className={inputClassName(formik?.errors?.azi_notifica_wa)} />
                                                <ErrorMessage name="azi_notifica_wa" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Notifica Email 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_notifica_email"  onChange={formik.handleChange}  value={formik.values.azi_notifica_email}   label="Azi Notifica Email" type="text" placeholder="accedere Azi Notifica Email"        className={inputClassName(formik?.errors?.azi_notifica_email)} />
                                                <ErrorMessage name="azi_notifica_email" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Email Ordine1 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_email_ordine1"  onChange={formik.handleChange}  value={formik.values.azi_email_ordine1}   label="Azi Email Ordine1" type="email" placeholder="accedere Azi Email Ordine1"        className={inputClassName(formik?.errors?.azi_email_ordine1)} />
                                                <ErrorMessage name="azi_email_ordine1" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Email Ordine2 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_email_ordine2"  onChange={formik.handleChange}  value={formik.values.azi_email_ordine2}   label="Azi Email Ordine2" type="email" placeholder="accedere Azi Email Ordine2"        className={inputClassName(formik?.errors?.azi_email_ordine2)} />
                                                <ErrorMessage name="azi_email_ordine2" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Email Ordine3 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_email_ordine3"  onChange={formik.handleChange}  value={formik.values.azi_email_ordine3}   label="Azi Email Ordine3" type="email" placeholder="accedere Azi Email Ordine3"        className={inputClassName(formik?.errors?.azi_email_ordine3)} />
                                                <ErrorMessage name="azi_email_ordine3" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Modalita Pagamento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="modalita_pagamento"  onChange={formik.handleChange}  value={formik.values.modalita_pagamento}   label="Modalita Pagamento" type="text" placeholder="accedere Modalita Pagamento"        className={inputClassName(formik?.errors?.modalita_pagamento)} />
                                                <ErrorMessage name="modalita_pagamento" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Partita Iva 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_partita_iva"  onChange={formik.handleChange}  value={formik.values.azi_partita_iva}   label="Azi Partita Iva" type="text" placeholder="accedere Azi Partita Iva"        className={inputClassName(formik?.errors?.azi_partita_iva)} />
                                                <ErrorMessage name="azi_partita_iva" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Banca 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_banca"  onChange={formik.handleChange}  value={formik.values.azi_banca}   label="Azi Banca" type="text" placeholder="accedere Azi Banca"        className={inputClassName(formik?.errors?.azi_banca)} />
                                                <ErrorMessage name="azi_banca" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Iban 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_iban"  onChange={formik.handleChange}  value={formik.values.azi_iban}   label="Azi Iban" type="text" placeholder="accedere Azi Iban"        className={inputClassName(formik?.errors?.azi_iban)} />
                                                <ErrorMessage name="azi_iban" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Swift 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_swift"  onChange={formik.handleChange}  value={formik.values.azi_swift}   label="Azi Swift" type="text" placeholder="accedere Azi Swift"        className={inputClassName(formik?.errors?.azi_swift)} />
                                                <ErrorMessage name="azi_swift" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Sdi 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_sdi"  onChange={formik.handleChange}  value={formik.values.azi_sdi}   label="Azi Sdi" type="text" placeholder="accedere Azi Sdi"        className={inputClassName(formik?.errors?.azi_sdi)} />
                                                <ErrorMessage name="azi_sdi" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Pec 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_pec"  onChange={formik.handleChange}  value={formik.values.azi_pec}   label="Azi Pec" type="text" placeholder="accedere Azi Pec"        className={inputClassName(formik?.errors?.azi_pec)} />
                                                <ErrorMessage name="azi_pec" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azi Ruolo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azi_ruolo"  onChange={formik.handleChange}  value={formik.values.azi_ruolo}   label="Azi Ruolo" type="text" placeholder="accedere Azi Ruolo"        className={inputClassName(formik?.errors?.azi_ruolo)} />
                                                <ErrorMessage name="azi_ruolo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiorna" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
AziendaEditPage.defaultProps = {
	primaryKey: 'azi_id',
	pageName: 'azienda',
	apiPath: 'azienda/edit',
	routeName: 'aziendaedit',
	submitButtonLabel: "Aggiorna",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Registra aggiornato correttamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default AziendaEditPage;
